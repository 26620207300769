/**
 * Map of all Contentful inline types. Inline contain inline or text nodes.
 */
enum INLINES {
  HYPERLINK = 'hyperlink',
  ENTRY_HYPERLINK = 'entry-hyperlink',
  ASSET_HYPERLINK = 'asset-hyperlink',
  EMBEDDED_ENTRY = 'embedded-entry-inline'
}

export default INLINES;
